<template>
  <section class="guardian-page">
    <div class="guardian-box">
      <div class="guardian-head">
        <div class="container">
          <div class="guardian-content">
            <div class="icon-box">
              <div class="row">
                <div class="col-6">
                  <div class="guardian-icon">
                    <div class="img-circle">
                      <img :src="$auth.user().profile_image" alt="" />
                    </div>
                    <div class="guardian-info">
                      <h5>{{ $auth.loggedInRole().name }}</h5>
                      <h1>{{ $auth.user().full_name }}</h1>
                      <h3>{{ $auth.user().email }}</h3>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="guardian-icon-pt-2">
                    <a href=""><i class="fas fa-user"></i></a>
                    <a @click="logout()"><i class="fas fa-power-off"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="guardian-features">
        <div class="container">
          <div class="features">
            <a class="featured_icon fees">
              <span><i class="fas fa-receipt"></i></span>
              <h2>Fees</h2>
            </a>

            <a class="featured_icon">
              <span><i class="fas fa-comment-alt"></i></span>
              <h2>SMS</h2>
            </a>

            <a class="featured_icon">
              <span><i class="fas fa-money-bill-alt"></i></span>
              <h2>Transaction</h2>
            </a>

            <a class="featured_icon">
              <span><i class="fas fa-video"></i></span>
              <h2>Live Class</h2>
            </a>
          </div>
        </div>
      </div>

      <guardian-children-list></guardian-children-list>

      <div class="menu-item-box-container">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <menu-item-box-statement
                title="Statement"
                info="Search Reports, statements etc."
              ></menu-item-box-statement>
            </div>

            <div class="col-lg-3">
              <menu-item-box-fees
                title="Fees"
                info="Search Reports."
              ></menu-item-box-fees>
            </div>

            <div class="col-lg-3">
              <menu-item-box-course
                title="Courses"
                info="List of available online course."
              ></menu-item-box-course>
            </div>

            <div class="col-lg-3">
              <menu-item-box-result
                title="Results"
                info="Result of a student."
              ></menu-item-box-result>
            </div>

            <div class="col-lg-3">
              <menu-item-box-library
                title="Library"
                info="Manage books transaction."
              ></menu-item-box-library>
            </div>

            <div class="col-lg-3">
              <menu-item-box-transport
                title="Transport"
                info="Students transport report."
              ></menu-item-box-transport>
            </div>

            <div class="col-lg-3">
              <menu-item-box-gallery
                title="Gallery"
                info="Manage schools gallery images."
              ></menu-item-box-gallery>
            </div>

            <div class="col-lg-3">
              <menu-item-box-notice
                title="Notice"
                info="Manage/Publish new notices."
              ></menu-item-box-notice>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- =====================  Buttom Sheet Ended  =====================  -->

    <router-view></router-view>
  </section>
</template>
<script>
export default {
  data: () => ({
    childrens: [],
    sheet: false,
    exams: [],
    selected_students: {
      exam_id: "",
    },
    filteredChildren: [],
    expand: 2,
    children_data_length: "",
  }),
  mounted() {
    // this.fetchGuardianChildern();
    this.getExams();
  },

  methods: {
    getExams() {
      this.$rest.get("/api/exam").then(({ data }) => {
        this.exams = data.data.map((item) => {
          return { value: item.id, text: item.name };
        });
      });
    },
    fetchGuardianChildern() {
      this.$rest
        .get("/api/guardian-children")
        .then(({ data }) => {
          if (data.data.length == 1) {
            this.expand = 1;
            this.childrens = data.data;
          }
          if (data.data.length > 2) {
            this.children_data_length = data.data.length - this.expand;
            this.childrens = data.data;
            this.showHideChildren(2);
          }
        })
        .then((e) => {});
    },
    studentResults(value, index) {
      this.selected_students.enroll_id = value.academic.enroll_id.toString();
      this.selected_students.enrollCode = value.academic.enroll_code.toString();
    },
    filterResult() {
      if (this.selected_students.exam_id == "") {
        return this.$events.fire("notification", {
          message: "Please select Exam ",
          status: "error",
        });
      }
      this.sheet = false;
      this.$router.push({
        name: "exam-result",
        params: {
          examId: this.selected_students.exam_id,
          enrollId: this.selected_students.enroll_id,
          enrollCode: this.selected_students.enrollCode,
        },
      });
    },
    logout() {
      this.$storage.delete("_token");

      //do not remove batch since batch should not be reset every time the user logs in
      // this.$storage.delete("batch");
      this.$storage.delete("_permissions");
      this.$storage.delete("_user");
      this.$storage.delete("_role");
      this.$storage.delete("_currentFiscalYear");
      this.$router.replace({ name: "login" });
      this.$storage.sessionRemove("_batch_se");
    },
    showHideChildren(numberofchildern) {
      let filterd_array = [];
      for (let step = 0; step < numberofchildern; step++) {
        filterd_array.push(this.childrens[step]);
      }
      this.filteredChildren = filterd_array;
    },
  },
  watch: {
    expand: function(v) {
      this.showHideChildren(v);
    },
  },
};
</script>





